/* eslint-disable */

import { Store } from 'vuex'
import { every } from 'lodash-es'
import { Account, Products } from '@one/types'
import jwt_decode from "jwt-decode";

const ERRORS = {
  PRODUCTS_INVALID: 'PRODUCTS_INVALID',
  PRODUCT_INVALID: 'PRODUCT_INVALID',
}
export interface GTMOptions {
  layer: string
  pageTracking: boolean
  pageViewEventName: string
  respectDoNotTrack: boolean
  dev: boolean
  query: any
  scriptURL: string
  noscriptURL: string
  env: any
  defaultCurrency?: string | Function
  id: string | Function
}

export enum GTMClientType {
  B2B = 'B2B',
  B2C = 'B2C',
  Anonymous = 'Anonymous',
}
export enum GTMEventType {
  ADD_TO_CART = 'EEProductAddToCart',
  PROMOTION_CLICK = 'EEPromotionClick',
  REMOVE_FROM_CART = 'EEProductRemoveFromCart',
  PRODUCT_SEARCH_RESULTS = 'EEProductSearchResults',
  PRODUCT_SEARCH_SUGGEST_RESULTS = 'EEProductSearchSuggestResults',
  PRODUCT_DETAILS = 'EEProductDetails',
  PRODUCT_CLICK = 'EEProductClick',
  PRODUCTS_IMPRESSION = 'EEProductsImpression',
  CHECKOUT = 'EECheckout',
  TRANSACTION = 'EETransaction',
}

export enum GTMNewEventType {
  ADD_TO_CART = 'add_to_cart',
  VIEW_ITEM = 'view_item',
  VIEW_ITEM_LIST = 'view_item_list',
  VIEW_CART = 'view_cart',
  PURCHASE = 'purchase',
  REMOVE_FROM_CART = 'remove_from_cart',
  WISHLIST = 'add_to_wishlist',
  GENERATE_LEAD = 'generate_lead',
  SELECT_ITEM = 'select_item',
}

export enum GTMListType {
  OTHER = 'other',
  ACCESSORY = 'accessory',
  RELATED = 'related',
  PERSONALIZE = 'personalize',
  SUGGEST = 'suggest',
  HOME = 'home',
  STATIC_PAGE = 'static-page',
  CMS_TOP_PRODUCTS = 'cms-top-products',
  PRODUCTS_DETAILS = 'products-details',
  RECOMMENDED_PRODUCTS = 'recommended-products',
  PRODUCTS_LISTING = 'products-listing',
  CATALOG_VIEW = 'catalog-view',
}

export interface ProductItem {
  item_id: string
  item_name: string
  item_brand: string
  category?: string
  item_category?: string
  item_category2?: string
  item_category3?: string
  item_category4?: string
  item_category5?: string
  item_list_id?: string
  item_list_name?: string
  item_variant?: string
  affiliation?: string
  coupon?: string
  discount?: number
  index?: number
  location_id?: string
  price?: number
  quantity?: number
  promotion_id?: string
  promotion_name?: string
}

export interface GTMEvent {
  event?: GTMEventType | string
  [key: string]: any
}

export interface BaseData {
  id: string
  name: string
  list?: string
  brand?: string | any
  category?: string
  variant?: string
  position?: number
  price?: any
}
export interface ProductImpressionEvent extends GTMEvent {
  ecommerce: {
    item_list_id: string
    item_list_name: string
    items: Array<ProductItem>
  }
}

export type ImpressionData = BaseData

export interface ProductData extends BaseData {
  quantity?: number
  coupon?: string
}

export interface PromotionData {
  id: string
  name: string
  creative?: string
  position?: string
}

export interface ActionData {
  id: string
  affiliation?: string
  revenue?: number
  tax?: number
  shipping?: number
  coupon?: string
  list?: string
  step?: number
  option?: string
}

export interface Impression extends GTMEvent {
  ecommerce: {
    currencyCode?: string
    impressions: Array<ImpressionData>
    detail?: {
      products: Array<BaseData>
    }
    promoView?: any
  }
}

export interface TransactionData {
  id: string
  affiliation?: string
  revenue?: number
  tax?: number
  shipping?: number
}

interface AdditionalEventFields {
  clientType: GTMClientType
  organization: string | null
  tin: string | null
  userName: string | null
  segment: string | null
  email: string | null
  defaultWarehouse: string | null
  managerName: string | null
}

export interface ProductImpression extends GTMEvent {
  ecommerce: {
    item_list_id: string
    item_list_name: string
    items: Array<ImpressionData>
  }
}

export interface NewTransactionData {
  transaction_id: string
  affiliation?: string
  value: number | string
  tax?: number | string
  shipping?: number | string
  coupon?: string
}

export namespace NewActions {
  export interface ViewItem extends GTMEvent {
    ecommerce: {
      currency: string
      value: string
      items: Array<ProductItem>
    }
  }

  export interface GenerateLead extends GTMEvent {
    value?: string | number
    currency?: string | number
  }

  export interface AddToCart extends GTMEvent {
    ecommerce: {
      coupon?: string
      currency?: string
      value?: string | number
      items: Array<ProductItem>
    }
  }

  export interface AddToWishlist extends GTMEvent {
    ecommerce: {
      currency?: string
      value?: string | number
      items: Array<ProductItem>
    }
  }

  export interface RemoveProductFromCart extends GTMEvent {
    ecommerce: {
      currency?: string
      value?: string | number
      items: Array<ProductItem>
    }
  }

  export interface ViewItemList extends GTMEvent {
    ecommerce: {
      currency?: string
      value?: string | number
      items: Array<ProductItem>
    }
  }

  export interface NewPurchase extends GTMEvent {
    ecommerce: {
      transaction_id: string
      currency: string
      value: string | number
      tax?: string | number
      shipping?: string | number
      coupon?: string
      items: Array<ProductItem>
    }
  }

  export interface SelectItem extends GTMEvent {
    ecommerce: {
      item_list_name: string
      item_list_id: string
      items: Array<ProductItem>
    }
  }
}

export namespace Actions {
  export interface Click extends GTMEvent {
    ecommerce: {
      click: {
        actionField?: {
          list: string
        }
        products: Array<ProductData>
      }
    }
    eventCallback?: Function
  }

  export interface Detail extends GTMEvent {
    ecommerce: {
      detail: {
        actionField?: {
          list: string
        }
        products: Array<ProductData>
      }
    }
  }

  export interface RemoveFromCart extends GTMEvent {
    ecommerce: {
      currencyCode?: string
      remove: {
        products: Array<ProductData>
      }
    }
  }

  export interface AddToCart extends GTMEvent {
    ecommerce: {
      currencyCode?: string
      add: {
        products: Array<ProductData>
      }
    }
  }

  export interface Checkout extends GTMEvent {
    ecommerce: {
      checkout: {
        actionField: {
          step: number
        }
        products?: Array<ProductData>
      }
      promoView?: {
        promotion: Array<ProductData>
      }
    }
  }

  export interface ProductSearchResultPayload {
    query: string
    skuProducts: Array<string>
    searchIndexes: number
  }

  export interface ProductSearchSuggestResults extends GTMEvent {
    ecommerce: {
      tenant: string
      clientId?: string
      userId?: string
      query: string
      skuProducts: Array<string>
      searchIndexes: number
    }
  }

  export interface ProductSearchResults extends GTMEvent {
    ecommerce: {
      tenant: string
      clientId?: string
      userId?: string
      query: string
      skuProducts: Array<string>
      searchIndexes: number
    }
  }

  export interface CheckoutOption extends GTMEvent {
    ecommerce: {
      // eslint-disable-next-line camelcase
      checkout_option: {
        actionField: {
          step: number
          option: string
        }
      }
    }
  }

  export interface Transaction extends GTMEvent {
    ecommerce: {
      purchase: {
        actionField: TransactionData
        products: Array<ImpressionData>
      }
    }
  }

  export interface Purchase extends GTMEvent {
    ecommerce: {
      detail: {
        actionField: {
          id: string
          affiliation?: string
          revenue?: number
          tax?: number
          shipping?: number
          coupon?: string
        }
      }
      products: Array<ImpressionData>
    }
  }
}

export class GTM {
  ctx: any
  store: Store<any>
  id!: string
  options: GTMOptions = {
    layer: 'dataLayer',
    pageTracking: true,
    pageViewEventName: 'PageView',
    respectDoNotTrack: false,
    defaultCurrency: 'PLN',
    dev: true,
    query: {},
    scriptURL: '//www.googletagmanager.com/gtm.js',
    noscriptURL: '//www.googletagmanager.com/ns.html',
    env: {}, // env is supported for backward compability and is alias of query
    id: '',
  }

  constructor(ctx: any, options?: Partial<GTMOptions>) {
    this.ctx = ctx
    this.store = this.ctx.store
    this.options = {
      ...this.options,
      ...options,
    }
  }

  get isEnabled() {
    return /^GTM-[A-Z0-9]{1,7}/.test(this.id)
  }

  get currency() {
    return typeof this.options.defaultCurrency === 'function'
      ? this.options.defaultCurrency()
      : this.options.defaultCurrency
  }

  fetchGTMId = () => (typeof this.options.id === 'string' ? this.options.id : this.options.id())

  setDataLayer() {
    // @ts-ignore
    window[this.options.layer] = window[this.options.layer] || []
  }

  insertGTMTag() {
    const firstScriptTag: HTMLElement = document.getElementsByTagName('script')[0]
    const gtmScriptElement: HTMLElement = document.createElement('script')
    const queryParams = {
      id: this.id,
      l: this.options.layer,
    }

    const queryString = Object.keys(queryParams)
      // @ts-ignore
      .filter((key) => queryParams[key] !== null && queryParams[key] !== undefined)
      // @ts-ignore
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
      .join('&')

    // @ts-ignore
    gtmScriptElement.async = true
    // @ts-ignore
    gtmScriptElement.src = `${
      this.options.scriptURL || '//www.googletagmanager.com/gtm.js'
    }?${queryString}`
    // @ts-ignore
    firstScriptTag.parentNode.insertBefore(gtmScriptElement, firstScriptTag)
  }

  async init() {
    this.id = await this.fetchGTMId()
    if (!this.isEnabled) {
      return
    }
    this.setDataLayer()
    this.insertGTMTag()
    this.pushEvent({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    })

    if (this.options.pageTracking && (!this.options.respectDoNotTrack || !this.hasDNT())) {
      this.initPageTracking()
    }
  }

  initPageTracking() {
    this.ctx.app.router.afterEach((to: any) => {
      setTimeout(() => {
        // @ts-ignore
        window[this.options.layer].push(
          to.gtm || {
            event: this.options.pageViewEventName,
            pageType: 'PageView',
            pageUrl: to.fullPath,
            routeName: to.name,
            ...this.getAdditionalEventFields(),
          },
        )
      }, 0)
    })
  }

  private getAdditionalEventFields(): AdditionalEventFields {
    const app = this.ctx.app
    const client: Account.App.Responses.ClientRestView | null = this.store.getters['account/getClient']
    const currentUser: Account.App.Responses.User | null = this.store.state.account.currentUser
    return {
      clientType: app.$auth.isAuthenticated
        ? app.$utils.isB2B
          ? GTMClientType.B2B
          : GTMClientType.B2C
        : GTMClientType.Anonymous,
      // @ts-ignore TODO: Brakuje poprawnego typowania!
      tin: client?.tin ?? null,
      userName: currentUser?.name ?? null,
      email: currentUser?.email ?? null,
      // @ts-ignore TODO: Brakuje poprawnego typowania!
      segment: client?.segments?.mainSegment?.name ?? null,
      organization: client?.name ?? null,
      defaultWarehouse: client?.warehouseId ?? null,
      managerName: client?.accountManager?.name ?? null,
    }
  }

  isGA4Event(obj: GTMEvent): boolean {
    if (!obj) {
      return false
    }

    return this.isValueInEnum(obj.event!, GTMNewEventType)
  }

  isValueInEnum(value: string, enumeration: any): boolean {
    return Object.values(enumeration).includes(value)
  }

  pushGA4Event(obj: GTMEvent) {
    window[this.options.layer]?.push({
      ecommerce: null,
    })

    window[this.options.layer]?.push({
      ...obj,
    })
  }

  private pushEvent(obj: GTMEvent) {
    if (!this.isEnabled) {
      return
    }
    try {
      // @ts-ignore
      if (!window || !window[this.options.layer]) {
        throw new Error('missing GTM dataLayer')
      }
      if (typeof obj !== 'object') {
        throw new TypeError('event should be an object')
      }
      // eslint-disable-next-line no-prototype-builtins
      if (!obj.hasOwnProperty('event')) {
        throw new Error('missing event property')
      }
      if (!this.isEnabled) {
        throw new Error('gtm is disabled')
      }
      if (this.isGA4Event(obj)) {
        this.pushGA4Event(obj)
      } else {
        // @ts-ignore
        window[this.options.layer].push({
          ...obj,
          ...this.getAdditionalEventFields(),
        })
      }
    } catch (err) {
      console.error('[ERROR] [GTM]', err)
    }
  }

  hasDNT() {
    return (
      navigator.doNotTrack === 'yes' ||
      navigator.doNotTrack === '1' ||
      // @ts-ignore
      navigator.msDoNotTrack === '1' ||
      // @ts-ignore
      (window.external &&
        // @ts-ignore
        window.external.msTrackingProtectionEnabled &&
        // @ts-ignore
        window.external.msTrackingProtectionEnabled())
    )
  }

  generateLead(): GTM {
    if (!this.isEnabled) {
      return this
    }
    const leadEvent: NewActions.GenerateLead = {
      event: GTMNewEventType.GENERATE_LEAD,
    }
    this.pushEvent(leadEvent)
    return this
  }

  purchase(transaction: NewTransactionData, products: Array<ProductItem>): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (!this.checkNewProductsValidity(products)) {
      console.warn(Error(ERRORS.PRODUCTS_INVALID), products)
      return this
    }
    const transactionEvent: NewActions.NewPurchase = {
      // analogicznie jak view-cart
      event: GTMNewEventType.PURCHASE,
      ecommerce: {
        ...transaction,
        currency: this.currency,
        items: products,
      },
    }
    this.pushEvent(transactionEvent)
    return this
  }

  checkoutOption(step: number, option: string): GTM {
    if (!this.isEnabled) {
      return this
    }
    const checkoutOptionEvent: Actions.CheckoutOption = {
      event: GTMEventType.CHECKOUT,
      ecommerce: {
        checkout_option: {
          actionField: {
            step,
            option,
          },
        },
      },
    }
    this.pushEvent(checkoutOptionEvent)
    return this
  }

  get tenant() {
    return this.store.state?.auth?.iss ?? null
  }

  get clientId() {
    const token = this.store.state?.auth?.accessToken;
    const decoded = token ? jwt_decode(token) : null;

    // @ts-ignore
    return decoded?.cli ?? null
  }

  get userId() {
    return this.store.state?.auth?.sub ?? null
  }

  productSearchResults(payload: Actions.ProductSearchResultPayload): GTM {
    if (!this.isEnabled) {
      return this
    }
    const productSearchResultsEvent: Actions.ProductSearchResults = {
      event: GTMEventType.PRODUCT_SEARCH_RESULTS,
      ecommerce: {
        tenant: this.tenant,
        clientId: this.clientId,
        userId: this.userId,
        ...payload,
      },
    }
    this.pushEvent(productSearchResultsEvent)
    return this
  }

  productSearchSuggestResults(payload: Actions.ProductSearchResultPayload): GTM {
    if (!this.isEnabled) {
      return this
    }
    const productSearchSuggestEvent: Actions.ProductSearchSuggestResults = {
      event: GTMEventType.PRODUCT_SEARCH_SUGGEST_RESULTS,
      ecommerce: {
        tenant: this.tenant,
        clientId: this.clientId,
        userId: this.userId,
        ...payload,
      },
    }
    this.pushEvent(productSearchSuggestEvent)
    return this
  }

  productRemove(product: ProductData, currencyCode?: string): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCT_INVALID), product)
      return this
    }
    const productRemoveEvent: Actions.RemoveFromCart = {
      event: GTMEventType.REMOVE_FROM_CART,
      ecommerce: {
        currencyCode: currencyCode || this.currency,
        remove: {
          products: [product],
        },
      },
    }
    this.pushEvent(productRemoveEvent)
    return this
  }

  productNewRemove(product: ProductItem, lineValue?: string | number): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isNewProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCT_INVALID), product)
      return this
    }

    const productWithCategories = this.setProductCategories(product)
    const productRemoveEvent: NewActions.RemoveProductFromCart = {
      event: GTMNewEventType.REMOVE_FROM_CART,
      ecommerce: {
        currency: this.currency,
        value: lineValue,
        items: [productWithCategories],
      },
    }
    this.pushEvent(productRemoveEvent)
    return this
  }

  productAdd(product: ProductItem, lineValue?: string | number): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isNewProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCT_INVALID), product)
      return this
    }
    const productWithCategories = this.setProductCategories(product)
    const productAddEvent: NewActions.AddToCart = {
      event: GTMNewEventType.ADD_TO_CART,
      ecommerce: {
        currency: this.currency,
        value: lineValue || 0,
        items: [productWithCategories],
      },
    }
    this.pushEvent(productAddEvent)
    return this
  }

  productClick(product: ProductData, where: string) {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCT_INVALID), product)
      return this
    }
    const productClickEvent: Actions.Click = {
      event: GTMEventType.PRODUCT_CLICK,
      ecommerce: {
        click: {
          actionField: {
            list: where,
          },
          products: [product],
        },
      },
    }
    this.pushEvent(productClickEvent)
    return this
  }

  productImpression(products: Array<ProductItem>, itemListId?, itemListName?): GTM {
    if (!this.isEnabled) {
      return this
    }
    const maxImpressionsCount: number = 15
    const pushChunkedImpressions = (impressionsChunk: Array<ProductItem>) => {
      const items = impressionsChunk.map((p) => this.setProductCategories(p))
      const impressionObject: ProductImpressionEvent = {
        event: GTMNewEventType.VIEW_ITEM_LIST,
        ecommerce: {
          item_list_id: itemListId,
          item_list_name: itemListName,
          items: items,
        },
      }
      this.pushEvent(impressionObject)
    }
    if (products.length > maxImpressionsCount) {
      while (products.length) {
        const chunk = products.splice(0, maxImpressionsCount)
        pushChunkedImpressions(chunk)
      }
    } else {
      pushChunkedImpressions(products)
    }
    return this
  }

  productDetails(product: ProductItem) {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isNewProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCT_INVALID), product)
      return this
    }
    const productWithCategories = this.setProductCategories(product)
    const productDetailsEvent: NewActions.ViewItem = {
      event: GTMNewEventType.VIEW_ITEM,
      ecommerce: {
        currency: this.currency,
        value: productWithCategories.price,
        items: [productWithCategories],
      },
    }
    this.pushEvent(productDetailsEvent)
    return this
  }

  checkout(products: Array<ProductItem>, productsValue: number, coupon?: string): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (products) {
      if (!this.checkNewProductsValidity(products)) {
        console.warn(Error(ERRORS.PRODUCTS_INVALID), products)
        return this
      }
    }
    const productsWithCategories = products.map((p) => this.setProductCategories(p))
    const checkoutEvent: NewActions.AddToCart = {
      event: GTMNewEventType.VIEW_CART,
      ecommerce: {
        coupon,
        currency: this.currency,
        value: productsValue,
        items: productsWithCategories,
      },
    }
    this.pushEvent(checkoutEvent)
    return this
  }

  selectItem(product: ProductItem, itemListName: string = '', itemListId: string = ''): GTM {
    if (!this.isEnabled) {
      return this
    }

    const productWithCategories = this.setProductCategories(product)
    const selectEvent: NewActions.SelectItem = {
      event: GTMNewEventType.SELECT_ITEM,
      ecommerce: {
        item_list_id: itemListId,
        item_list_name: itemListName,
        items: [productWithCategories],
      },
    }

    this.pushEvent(selectEvent)
    return this
  }

  addToWishlist(product: ProductItem, productValue: number = 0): GTM {
    if (!this.isEnabled) {
      return this
    }
    if (!this.isNewProductValid(product)) {
      console.warn(Error(ERRORS.PRODUCTS_INVALID), product)
      return this
    }
    const productWithCategories = this.setProductCategories(product)
    const addToWishlistEvent: NewActions.AddToWishlist = {
      event: GTMNewEventType.WISHLIST,
      ecommerce: {
        currency: this.currency,
        value: productValue,
        items: [productWithCategories],
      },
    }
    this.pushEvent(addToWishlistEvent)
    return this
  }

  private checkNewProductsValidity(products: Array<ProductItem>): boolean {
    return (
      !!products.length &&
      every(
        products.map((p: ProductItem) => this.isNewProductValid(p)),
        Boolean,
      )
    )
  }

  private checkProductsValidity(products: Array<ProductData>): boolean {
    return (
      !!products.length &&
      every(
        products.map((p: ProductData) => this.isProductValid(p)),
        Boolean,
      )
    )
  }

  private isNewProductValid(product: ProductItem): boolean {
    const requiredFields = ['item_id', 'item_name']
    return every(
      requiredFields.map((fieldName: string) => fieldName in product),
      Boolean,
    )
  }

  private isProductValid(product: ProductData): boolean {
    const requiredFields = ['id', 'name']
    return every(
      requiredFields.map((fieldName: string) => fieldName in product),
      Boolean,
    )
  }

  private setProductCategories(product) {
    if (!product.category) {
      return product
    }

    const categories = product.category.split('/').filter((c) => c !== '')
    const newProduct = product

    for (let c = 0; c < categories.length; c++) {
      if (c === 0) {
        newProduct['item_category'] = categories[c]
      } else {
        newProduct[`item_category${c + 1}`] = categories[c]
      }
    }

    delete newProduct.category
    return newProduct
  }
}

export default (ctx: any, inject: Function) => {
  const $gtm = new GTM(ctx, {
    defaultCurrency: () =>
      ctx.store.getters['layout/getCurrency'] ? ctx.store.getters['layout/getCurrency'].code : null,
    id: () => ctx.store.state.cms.configuration.gtmId,
  })
  inject('gtm', $gtm)
  $gtm.init()
}
